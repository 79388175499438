import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import store from '../store'
import VueSessionStorage from 'vue-sessionstorage'

Vue.use(VueRouter)

const routes = [

  {
    path: '/',
    name: 'Home',
    component: () => import('../views/LandingPage.vue')
  },
  {
    path: '/debates',
    name: 'debates',
    component: () => import('../views/NewDebate.vue')
  },
  {
    path: '/resources',
    name: 'resources',
    component: () => import('../views/Acfcta-Resources.vue')
  },
  {
    path: '/events',
    name: 'events',
    component: () => import('../views/Events.vue')
  },
  {
    path: '/debates/:debateId',
    name: 'DebateDetails',
    component: () => import('../components/New/Debates/DebateDetails.vue')
  },
  {
    path: '/debates/:id/add_lead_opinion',
    name: "add-lead-opinion",
    component: () => import('../components/New/Debates/addThesis.vue')
  },
  {
    path: '/contest',
    name: 'contest',
    component: () => import('../views/Contest.vue')
  },
  {
    path: '/add_leaders_vision',
    name: "challenge_add_leaders_vision",
    component: () => import('../components/Challenge/LeadersVision.vue')
  },
  {
    path: '/challenge/:challengeID/leaders_vision',
    name: "challengeDetail",
    component: () => import('../components/Challenge/challengeDetail.vue')
  },
  {
    path: '/challenge/:challengeID/critique',
    name: "challenge_critique",
    component: () => import('../components/Challenge/challengeCritiqueDetail.vue')
  },
  {
    path: '/challenge/:challengeID/add_critique',
    name: "challenge_add_critique",
    component: () => import('../components/Challenge/ThinkClitique.vue')
  },
  {
    path:'/redirect',
    name:"redirect",
    component:()=>import('../components/Redirect.vue') 
  },
  {
    path:'/BOMA',
    name:"boma",
    component:()=>import('../components/New/Boma.vue') 
  },
  {
    path:'/jury-announcement',
    name:"afcfta-caravan-price",
    component:()=>import('../views/prize_landing_page/index.vue') 
  },
  {
    path:'/laureates',
    name:"laureates",
    component:()=>import('../views/prize_landing_page/winners.vue') 
  },
  {
    path:'/winners',
    name:"winners",
    component:()=>import('../views/prize_landing_page/winners.vue') 
  },
  {
    path: "/Boma2022/register",
    name: "register boma",
    meta: {
      authRequired: false,
    },
    component: () =>
      import("@/views/boma/index"),
  },
  // {
  //   path:'/boma',
  //   name:'AfricanBoma',
  //   component:()=>import('../views/AfricaBoma.vue')
  // }

  // {
  //   path:'/',
  //   name:"home",
  //   component:()=>import('../views/Home.vue')
  // },

  // {
  //   path:'/debates',
  //   name:"Debate",
  //   component:()=>import('../views/Debate.vue')
  // },
  // {
  //   path:'/debatedetails/:debateId',
  //   name:"Debatedetails.vue",
  //   component:()=>import('../components/Debate/Debatedetails.vue')
  // },
  // {
  //   path:'/debate/:id/add_lead_opinion',
  //   name:"add-lead-opinion",
  //   component:()=>import('../components/Debate/addThesis.vue')
  // },
  // {
  //   path:'/debate/:debateId/lead-opinion',
  //   name:'lead-opinion-details',
  //   component:()=>import('../components/Debate/leadOpinionDetals.vue')
  // },
  // {
  //   path:'/debate/:debateID/critique',
  //   name:"debate-critique",
  //   component:()=>import('../components/Debate/critiqueDetail.vue')
  // }, 
  // {
  //   path:'/debate/:debateID/resolution',
  //   name:"debate-resolution",
  //   component:()=>import('../components/Debate/ResolutionDetail.vue')
  // },  
  // {
  //   path:'/debate/:debateID/contributions',
  //   name:"debate-contributions",
  //   component:()=>import('../components/Debate/AllContributions.vue')
  // }, 
  // {
  //   path:'/debate/:debateID/add_lead_opinion/:thesisID/add_cretique',
  //   name:"add-critique",
  //   component:()=>import('../components/Debate/add-anti-thesis.vue')
  // },
  // {
  //   path:'/debate/:debateID/lead_opinion/:thesisID/critique/:antithesisID/add_resolution',
  //   name:"add-resolution",
  //   component:()=>import('../components/Debate/add-synthesis.vue')
  // },
  // {
  //   path:'/articles',
  //   name:"articleComponent",
  //   component:()=>import('../components/articleComponent.vue')
  // },
  // {
  //   path:'/article/:articleID',
  //   name:"article-details",
  //   component:()=>import('../components/ArticleDetail.vue')
  // },
  // {
  //   path:'/challenge',
  //   name:"callenge",
  //   component:()=>import('../components/Challenge/Home.vue')
  // }, 
  // {
  //   path:'/challenge/:challengeID/contributions',
  //   name:"callenge-all-contributions",
  //   component:()=>import('../components/Challenge/AllContrinbutions.vue')
  // }, 
  // {
  //   path:'/challenge/add_leaders_vision',
  //   name:"challenge_add_leaders_vision",
  //   component:()=>import('../components/Challenge/LeadersVision.vue') 
  // },
  // {
  //   path:'/challenge/:challengeID/leaders_vision',
  //   name:"challengeDetail",
  //   component:()=>import('../components/Challenge/challengeDetail.vue')
  // },
  // {
  //   path:'/challenge/:challengeID/critique',
  //   name:"challenge_critique",
  //   component:()=>import('../components/Challenge/challengeCritiqueDetail.vue') 
  // },
  // {
  //   path:'/challenge/:challengeID/add_critique',
  //   name:"challenge_add_critique",
  //   component:()=>import('../components/Challenge/ThinkClitique.vue') 
  // },
  // {
  //   path:'/challenge/:challengeID/enterpreneurs_plan',
  //   name:"challenge_plan",
  //   component:()=>import('../components/Challenge/ChallengePlanDetail.vue') 
  // },
  // {
  //   path:'/challenge/:challengeID/critique/:critiqueID/add_plan',
  //   name:"challenge_add_plan",
  //   component:()=>import('../components/Challenge/solutionPlans.vue') 
  // },
  // {
  //   path:'/AfCFTA_resources',
  //   name:"Resource",
  //   component:()=>import('../views/Resources.vue')
  // }, 
  // {
  //   path:'/redirect',
  //   name:"redirect",
  //   component:()=>import('../components/Redirect.vue') 
  // },
  // {
  //   path: '*',
  //   name: "pageNotfound",
  //   component: () => import('../components/PageNotFound.vue')
  // },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
    const ticket = to.query.ticket
  //   if(to.path === "/" && ticket) {
  //     store.dispatch('validateServiceTicket',ticket)
  //     next('/')  
  // }
  if (ticket) {
    store.dispatch('validateServiceTicket', ticket)
      .then(response => {
        if (response) {
          window.history.replaceState({}, document.title, window.location.origin + '/')
          window.location.reload()
        }
      })
  }

  next()
})
export default router
